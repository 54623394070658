'use client';

import { createContext, useState, type PropsWithChildren } from 'react';

import type { PanelTypes } from '@/components/panels/panelsMap';

export enum PanelSize {
  Full,
  Large,
  Small,
}

export interface SidePanelStore {
  isOpen: boolean;
  Component: PanelTypes | undefined;
  panelSize: PanelSize | string;
  setSize: (size: PanelSize | number) => void;
  setIsOpen: (isOpen: boolean, size?: PanelSize | string) => void;
  setComponent: (component: PanelTypes) => void;
}

export const WorkspaceContext = createContext<{
  leftPanel: SidePanelStore;
  rightPanel: SidePanelStore;
} | null>(null);

export const WorkspaceProvider = ({ children }: PropsWithChildren) => {
  const [leftPanel, setLeftPanel] = useState<SidePanelStore>({
    isOpen: false,
    Component: undefined,
    panelSize: PanelSize.Large,
    setSize: (panelSize: PanelSize | number) =>
      setLeftPanel((state) => ({ ...state, panelSize })),
    setIsOpen: (isOpen: boolean, panelSize?: PanelSize | string) =>
      setLeftPanel((state) => ({
        ...state,
        isOpen,
        panelSize: panelSize ?? state.panelSize,
      })),
    setComponent: (Component: PanelTypes) =>
      setLeftPanel((state) => ({ ...state, Component })),
  });

  const [rightPanel, setRightPanel] = useState<SidePanelStore>({
    isOpen: false,
    Component: undefined,
    panelSize: PanelSize.Large,
    setSize: (panelSize: PanelSize | number) =>
      setRightPanel((state) => ({ ...state, panelSize })),
    setIsOpen: (isOpen: boolean, panelSize?: PanelSize | string) =>
      setRightPanel((state) => ({
        ...state,
        isOpen,
        panelSize: panelSize ?? state.panelSize,
      })),
    setComponent: (Component: PanelTypes) =>
      setRightPanel((state) => ({ ...state, Component })),
  });

  return (
    <WorkspaceContext.Provider value={{ leftPanel, rightPanel }}>
      {children}
    </WorkspaceContext.Provider>
  );
};
