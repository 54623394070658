import { Divider, Stack, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

import { Icon } from '@/components/shared/icon';

export interface SidePanelWrapperProps extends PropsWithChildren {
  close: () => void;
  icon?: React.ReactNode;
  hideBorder?: boolean;
  title: React.ReactNode;
  subtitle?: string;
  testIdPrefix?: string;
}

const getTestId = (testIdPrefix: string | undefined, suffix: string) =>
  testIdPrefix ? `${testIdPrefix}-${suffix}` : suffix;

export const SidePanelWrapper = (props: SidePanelWrapperProps) => {
  const { icon, title, subtitle, testIdPrefix, close, hideBorder, children } =
    props;

  return (
    <Stack className='h-full'>
      <Stack
        justifyContent='space-between'
        direction='row'
        alignItems='center'
        padding='16px 24px'
      >
        <Stack alignItems='center' direction='row' className='flex-1'>
          {icon && <div className='mr-[15px]'>{icon}</div>}
          <div className='flex flex-col flex-1'>
            {typeof title === 'string' ? (
              <Typography
                className='font-semibold'
                data-testid={getTestId(testIdPrefix, 'side-panel-title')}
              >
                {title}
              </Typography>
            ) : (
              title
            )}
          </div>
        </Stack>
        <div className='flex gap-4'>
          <Icon
            onClick={close}
            iconClass='material-symbols-close-rounded'
            data-testid={getTestId(testIdPrefix, 'side-panel-close-button')}
          />
        </div>
      </Stack>
      {subtitle && (
        <Typography
          className='px-[24px] pb-[20px] pt-[8px]'
          variant='subtitle1'
          fontSize={14}
        >
          {subtitle}
        </Typography>
      )}
      {!hideBorder && <Divider />}
      <div
        className='flex flex-col overflow-auto rounded-b-[16px] h-full'
        data-testid={getTestId(testIdPrefix, 'side-panel-body')}
      >
        {children}
      </div>
    </Stack>
  );
};
