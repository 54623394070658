import { styled } from '@mui/material';
import { forwardRef } from 'react';

interface IconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  > {
  iconClass: string;
  isActive?: boolean;
  color?: string;
}

const _Icon = forwardRef<HTMLElement, IconProps>(
  ({ iconClass, isActive, ...rest }, ref) => {
    return (
      <span ref={ref} {...rest}>
        <i className={iconClass}></i>
      </span>
    );
  }
);

export const Icon = styled(_Icon)((props) => ({
  display: 'inline-flex',
  padding: '4px',
  borderRadius: '4px',

  ...(props.onClick ? { cursor: 'pointer' } : {}),
  ...(props.isActive
    ? { backgroundColor: 'var(--mui-palette-primary-main)' }
    : {}),

  '& i': {
    margin: '2px',
    width: '20px',
    height: '20px',
    color: props.color
      ? props.color
      : props.isActive
        ? 'var(--mui-palette-background-default)'
        : 'var(--mui-palette-text-primary)',
  },
}));
