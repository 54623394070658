import type { Role } from '@repo/api-gw-sdk';

const rolesOrder: Record<string, number> = {
  '543bad56-e9b2-421f-8456-b43c53fcebfe': 0, // Account Viewer
  '379a1104-838a-4bf3-af96-da3af27c5712': 1, // Account Admin
  'd6afa067-d3a0-457e-923d-27cd26c9e5cb': 2, // Project Viewer
  '21d0ae2b-9bbc-4a41-bd5e-98011e9f10a5': 3, // Project Operator
  'a675e456-8602-4550-9c65-66583404e0d6': 4, // Project Admin
};

export const rolesComparer = (a: Role, b: Role) => {
  if (!a.isProjectRole) {
    if (a.isBuiltInRole) {
      if (!b.isProjectRole) {
        if (b.isBuiltInRole) {
          return rolesOrder[a.id] - rolesOrder[b.id]; // both builtin account roles
        } else {
          return -1; // a builtin account role, b custom account role
        }
      } else {
        return -1; // a builtin account role, b project role
      }
    } else {
      if (!b.isProjectRole) {
        if (b.isBuiltInRole) {
          return 1; // a custom account role, b builtin account role
        } else {
          return a.name.localeCompare(b.name); // both custom account roles
        }
      } else {
        return -1; // a custom account role, b project role
      }
    }
  }

  if (a.isBuiltInRole) {
    if (b.isProjectRole) {
      if (b.isBuiltInRole) {
        return rolesOrder[a.id] - rolesOrder[b.id]; // both builtin project roles
      } else {
        return -1; // a builtin project role, b custom project role
      }
    } else {
      return 1; // a builtin project role, b account role
    }
  } else {
    if (b.isProjectRole) {
      if (b.isBuiltInRole) {
        return 1; // a custom project role, b builtin project role
      } else {
        return a.name.localeCompare(b.name); // both custom project roles
      }
    } else {
      return 1; // a custom project role, b account role
    }
  }
};
